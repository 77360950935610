<template>
    <div class="people-list">
        <!-- 查詢 -->
        <section class="table-search">
            <div class="search-L">
                <el-select v-model="tableParams.attributionType" placeholder="请选择归属类" @change="reload" clearable  class="mr15">
                    <el-option v-for="item in workOrderCate" :key="item.value" :label="item.name" :value="item.value">
                    </el-option>
                </el-select>
                <el-input v-model="tableParams.kw" placeholder="请输入工单类型名称搜索" @input="kwChange" clearable
                />

            </div>
            <div class="search-R">
                <el-button type="primary" @click="onAdd()">新增常规工单类型</el-button>
            </div>
        </section>
        <!-- 表格 -->
        <Table :tableData="tableData" :column="workTypeColumns" border :headerCellStyle="headerCellStyle">
            <template slot-scope="scope" slot="operation" v-if="scope.row.attributionType == 0">
                <el-button type="text" size="medium" @click.stop="onEdit(scope.row)">编辑</el-button>
                <el-button type="text" size="medium" @click.stop="onDel(scope.row)">删除</el-button>
            </template>
        </Table>
        <!-- 分页 -->
        <section class="table-footer">
            <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
                :page-size="tableParams.pageSize" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
                :current-page.sync="currentPage" @current-change="onCurrentPage">
            </el-pagination>
        </section>
        <!-- 编辑弹框 -->
        <el-dialog :title="editTitle" :visible.sync="editShow" :close-on-click-modal="false" append-to-body width="30%"
            center @close="() => { $refs['form-ref'].resetFields(); }">
            <!-- 表单提交 -->
            <el-form :model="params" ref="form-ref" label-width="100px">
                <section class="form-main">
                    <el-form-item label="类型名称" prop="workSheetName"
                        :rules="[{ required: true, message: '请输入类型名称', trigger: 'blur' }]">
                        <el-input v-model.trim="params.workSheetName" autocomplete="off" maxlength="20"
                            placeholder="请输入类型名称"></el-input>
                    </el-form-item>
                </section>
                <section class="form-footer">
                    <el-button type="primary" @click="onSubmit('form-ref')">确定</el-button>
                </section>
            </el-form>
        </el-dialog>

    </div>
</template>

<script>
import { workCateList, updata, workTypeList, delType } from "@/api/examine/type"
import { workTypeColumns } from "@/db/tableColumns";
import { ObjToOpt } from "@/utils/utils";
import { workOrderType } from "@/db/objs";
export default {
    data() {
        return {
            workTypeColumns,
            workOrderType,
            ObjToOpt,
            headerCellStyle: {
                background: '#FAFAFA',
                color: '#000000',
            },
            // table表格
            currentPage: 1, // 当前页
            total: 0, // 总条数  
            tableData: [],//表格数据
            tableParams: {
                page: 1,
                pageSize: 10,
                kw: "",//查询关键字
                attributionType: "",//归属类
            },
            // 弹框
            editShow: false, //弹框开关
            editTitle: "",//弹框标题
            // from表单
            params: {
                workSheetName: "",//类型名称
            },
            workOrderCate: [],//工单归属类
        }
    },
    created() {

    },
    mounted() {
        this.getWorkCateList();// 【请求】归属类型列表
        this.getTypeList();// 【请求】表格数据
    },
    methods: {
        // 获取归属类型列表
        getWorkCateList() {
            workCateList().then((res) => {
                if (res.isSuccess == "yes") {
                    this.workOrderCate = res.data;
                }
            });
        },
        // 【请求】表格数据
        getTypeList() {
            let data = this.tableParams;
            workTypeList(data).then((res) => {
                if (res.isSuccess == "yes") {
                    this.tableData = res.data.list;
                    this.total = res.data.total;
                }
            });
        },
         // 【监听】表格模糊查询
         kwChange: _.debounce(function () {
            this.reload();
        }, 500),
        // 新增或编辑
        updataType() {
            let data = this.params;
            updata(data).then((res) => {
                if (res.isSuccess == "yes") {
                    this.editShow = false;
                    this.reload();// 【请求】表格数据
                }
            });
        },
        // 【监听】表格重载
        reload() {
            this.tableParams.page = 1;
            this.currentPage = 1;
            this.getTypeList();
        },
        // 【监听】表格分页点击
        onCurrentPage(page) {
            this.tableParams.page = page;
            this.getTypeList();
        },

        // 【监听】表格条数点击
        onSizeChange(pageSize) {
            this.tableParams.pageSize = pageSize;
            this.reload();
        },
        // 【监听】新增
        onAdd() {
            this.editShow = true;
            this.editTitle = "新增常规工单类型";
        },

        // 【监听】编辑
        onEdit(row) {
            this.editShow = true;
            this.editTitle = "编辑常规工单类型";
            this.params = JSON.parse(JSON.stringify(row));

        },
        onDel(row) {
            this.$confirm("是否删除该类型?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    delType({ workSheetTypeId: row.workSheetTypeId }).then((res) => {
                        if (res.isSuccess == "yes") {
                            this.$message({
                                type: "success",
                                message: "删除成功!",
                            })
                            this.reload();// 【请求】表格数据
                        }
                    })
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消",
                    });
                });
        },
        // 【监听】表单提交
        onSubmit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.updataType();
                } else {
                    return false;
                }
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.people-list {
    padding: .15rem;
}

.table-footer {
    margin-top: .1rem;
    display: flex;
    justify-content: flex-end;
}

.table-search {
    display: flex;
    justify-content: space-between;
    margin-bottom: .15rem;
}

.search-L {
    display: flex;
}

.form-footer {
    display: flex;
    justify-content: center;
}

.code {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.clear-head {
    width: .28rem;
    height: .28rem;
    border-radius: 50%;
    margin-right: .1rem;
}
</style>

<style>
.people-list .nike-name .cell {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>